<template>
    <v-row>
        <v-col cols="12">
            <base-card>
                <v-card-title class="my-0 py-0">
                    <div class="d-flex justify-space-between flex-wrap">
                        <v-btn
                            class="ma-3"
                            dark
                            color="primary"
                            @click="createCard"
                        >
                            <v-icon>mdi-plus</v-icon>
                            {{ $t('admin.cards.add') }}
                        </v-btn>
                    </div>
                </v-card-title>
                <v-card-title>
                    {{ $t('admin.cards.title') }}
                    <v-spacer />
                    <v-spacer />
                    <v-spacer />
                    <v-spacer />

                    <v-text-field
                        v-model="search"
                        :append-icon="!search ? 'mdi-magnify' : undefined"
                        clearable
                        :label="$t('common.search')"
                        single-line
                        hide-details
                    />
                </v-card-title>

                <!--                <role-create
            v-if="dialog"
            :dialog="dialog"
            :item="itemSelected"
            @update:dialog="dialog = $event"
            @update:table="fetchGames"
        />-->
                <!-- :pagination.sync="pagination" -->
                <v-data-table
                    :options.sync="options"
                    :headers="headers"
                    :footer-props="{ 'items-per-page-options': [5, 10, 30, 50, -1] }"
                    :items="items"
                    class="elevation-1"
                    :sort-by="sortby"
                    :multi-sort="true"
                    :search="search"
                    :custom-filter="filterTableItems"
                    @click:row="editItem"
                >
                    <template #item.id="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>

                    <template #item.type.name="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>

                    <template v-slot:item.image="{ value }">
                        <!--                        {{ images[value] ? images[value] : "No image "+value }}-->
                        <v-img
                            :src="getImagePath(value)"
                            :aspect-ratio="3 / 4"
                            height="120px"
                            width="90px"
                            class="my-1"
                        />
                    </template>

                    <template #item.question="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>
                    <template #item.active="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>
                    <template #item.order="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>

                    <template v-slot:item.created_at="{ value }">
                        <div :inner-html.prop="dateHelper.getDateTimeTableFormat(value)" />
                    </template>
                    <template #item.updated_at="{ value }">
                        <div :inner-html.prop="dateHelper.getDateTimeTableFormat(value)" />
                    </template>

                    <!--                    <template v-slot:item.role_id="{ item }">
    {{ itemsRoles.find((r) => r.id_role == item.role_id).name }}
</template>-->

                    <template v-slot:item.actions="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    small
                                    class="mr-2"

                                    v-bind="attrs"
                                    @click.stop="editItem(item)"
                                    v-on="on"
                                >
                                    mdi-pencil
                                </v-icon>
                            </template>

                            <span>{{ $t('common.edit') }}</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    small
                                    v-bind="attrs"
                                    @click.stop="deleteItem(item)"
                                    v-on="on"
                                >
                                    mdi-delete
                                </v-icon>
                            </template>

                            <span>{{ $t('common.delete') }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:no-data>
                        <v-btn
                            color="primary"
                            @click="fetchData"
                        >
                            {{ $t('admin.common.resetData') }}
                        </v-btn>
                    </template>
                </v-data-table>
            </base-card>

            <confirm-dialog
                ref="confirm"
            />
        </v-col>
    </v-row>
</template>

<script>
import axios from '@/plugins/axios'
// import RoleCreate from '@/components/admin/roles/rolesCreate'
import ConfirmDialog from '@/components/common/ConfirmDialog'
import { debounce } from "debounce";
import { mapGetters } from 'vuex'
import item from "../../../../../components/base/item";

export default {
    components: { /* RoleCreate, */ ConfirmDialog },
    data: function () {
        return {
            urlMain: 'admin/games/cards',
            /* dialog: false, */
            headers: [
                {
                    text: 'Id',
                    align: 'start',
                    sortable: true,
                    value: 'id'
                },
                {
                    text: this.$t('admin.cards.type'),
                    value: 'type.name'
                },
                {
                    text: this.$t('admin.cards.image'),
                    value: 'image'
                },
                {
                    text: this.$t('admin.cards.question'),
                    value: 'question'
                },
                {
                    text: this.$t('admin.cards.isActive'),
                    value: 'active'
                },
                {
                    text: this.$t('admin.cards.order'),
                    value: 'order'
                },
                {
                    text: this.$t('admin.common.createdAt'),
                    value: 'created_at'
                },
                {
                    text: this.$t('admin.common.updatedAt'),
                    value: 'updated_at'
                },
                // { text: "Role", value: "role_id" },
                {
                    text: this.$t('admin.common.actions'),
                    value: 'actions',
                    sortable: false
                }
            ],
            search: '',
            items: [],
            itemSelected: null,
            sortby: ['order'],
            options: {
                itemsPerPage: 5
            }
        }
    },
    watch: {
        dialog(val) {
            val || this.close()
        }
    },

    created() {
        this.fetchData();
    },

    mounted() {

    },

    methods: {
        fetchData: debounce(function (e) {
            this.fetchDataFinal(e);
        }, 500),

        async fetchDataFinal(data = this.options) {
            try {
                const result = await axios
                    .get(`${this.urlMain}`);

                this.initialize(result.data);
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        initialize($data) {
            let itemsFiltered = $data.cards ?? [];
            let type = this.$route.query.type;
            if (!isNaN(type)) {
                type = +type;
                itemsFiltered = itemsFiltered.filter((item) => item.card_type_id === type);
            }

            this.items = itemsFiltered;

            // this.pagination.totalItems = $data?.meta?.page?.total ?? 0;
        },
        createCard() {
            this.$router.push('/app/admin/cards/create');
        },
        /* setRole($data) {
      this.itemsRoles = $data;
  }, */
        editItem(item) {
            // this.itemSelected = item;
            // this.dialog = true;

            this.$router.push({ name: 'CardCreate', params: { item } })
        },
        async deleteItemConfirmed(item) {
            const urlAppend = item.id ? '/' + item.id : '';

            try {
                const result = await axios
                    .delete(this.urlMain + urlAppend);

                if (result.status === 200) {
                    this.fetchData();
                    // snackbar/
                    this.$store.dispatch('snackbar/success', this.$t('common.deleteOK'))
                }
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error) // "invalid_credentials"
                    console.log(error.response.status)
                    console.log(error.response.headers)
                }
            }
            this.$store.dispatch('loading/end')
        },
        async deleteItem(item) {
            if (
                await this.$refs.confirm.open(
                    this.$t('common.confirmTitle'),
                    this.$t('common.confirmMSGDelete')
                )
            ) {
                this.deleteItemConfirmed(item)
            }
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.itemSelected = null
            })
        },
        filterTableItems(value, search, item) {
            // console.log("filterTableItems", value);
            let filter = false;
            if (value != null &&
                search != null) {
                if (typeof value === 'string') {
                    if (value === item.created_at || value === item.updated_at) {
                        filter = this.dateHelper.getDateTimeTableFormat(value).includes(search);
                    } else { filter = value.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1 }
                } else if (typeof value === 'number') {
                    if (value == search) {
                        filter = true;
                    } else {
                        filter = (value + '').indexOf(search) !== -1;
                    }
                }
            }

            return filter;
        },
        getImagePath(image) {
            return image ? process.env.VUE_APP_API_ENDPOINT + 'imagesSimple?filename=games/cards/' + image : '';
        }
    },
    computed: {

    }
}
</script>
